<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-6 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Errores SQL</h3>
                            <div class="row breadcrumbs-top">
                                <div class="breadcrumb-wrapper col-12">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="/">General</a></li>
                                        <li class="breadcrumb-item active">Errores</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="content-header-right text-md-right col-md-6 col-12">
                            <div class="form-group">
                            
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <div id="versiones" v-if="show" class="content-header row d-none d-md-block ">
                            <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                <div class="media-body p-2 row">
                                    <div v-for="version in versiones" :key="version.Sistema" class="d-inline-block text-white ps-1 pe-1 pb-1 col-2">
                                        {{version.Sistema}}: {{version.Version}}
                                    </div>
                                </div>
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        </div>
                        
                        <div id="filtros" class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="card p-1">
                                    <div class="card-header">
                                        <h4 class="card-title">Busqueda Avanzada</h4>
                                    </div>
                                    
                                    <div class="card-content collapse show">
                                        <form>
                                            <div class="row">
                                                <div class="col-md-2 col-12 offset-md-1 form-group">
                                                    <label>Mostrar por Tipo:</label>
                                                    <select v-model="filtroTipo" class=" font-small-1 form-control">
                                                        <option value="todos">Todos</option>
                                                        <option value="sql">SQL</option>
                                                        <option value="exp">EXP</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-2 col-12 form-group">
                                                    <label>Mostrar por Fecha:</label>
                                                    <select v-model="filtroFecha" class=" font-small-1 form-control">
                                                        <option value="todo">Todo</option>
                                                        <option value="hoy">Hoy</option>
                                                        <option value="ayer">Ayer</option>
                                                        <option value="una">Hace una semana</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4 col-12 form-group offset-md-2">
                                                    <fieldset class="form-group title">
                                                        <legend style="width:unset;font-size:14px">Rango de Fechas</legend>
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <input type="date" v-model="filtroFechaIni" class=" font-small-1 form-control">
                                                            </div>
                                                            <div class="col-5">
                                                                <input type="date" v-model="filtroFechaFin" class=" font-small-1 form-control">
                                                            </div>
                                                            <div class="col-2 d-flex p-0">
                                                                <button type="reset" @click="filtroFechaIni = ''; filtroFechaFin = ''" class="btn-sm mt-0 me-1 btn-danger btn">Limpiar</button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2 col-12 offset-md-1 form-group">
                                                    <label for="f-sistema">Sistema</label>
                                                    <div class="input-group mb-2">
                                                        <input type="text" class="search form-control" v-model="filtroSistema" id="f-sistema" name="filterSistema">
                                                        <button class="btn btn-outline-secondary" type="reset" @click="filtroSistema = ''" id="button-addon2">x</button>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-2 col-12">
                                                    <label for="f-datospersonales">Datos Personales</label>
                                                    <div class="input-group">
                                                        <input type="text" class="search form-control" v-model="filtroDatos"  id="f-datospersonales" name="filterDatosPersonales">
                                                        <button class="btn btn-outline-secondary" @click="filtroDatos = ''" type="button">x</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-12 ">
                                                    <label for="f-query">Query</label>
                                                    <div class="input-group">
                                                        <input type="text" class="search form-control "  v-model="filtroQuery" id="f-query" name="filterQuery">
                                                        <button class="btn btn-outline-secondary" type="button" @click="filtroQuery = ''" >x</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-12 ">
                                                    <label for="f-error">Error</label>
                                                    <div class="input-group">
                                                        <input type="text" class="search form-control " v-model="filtroError" id="f-error" name="filterError">
                                                        <button class="btn btn-outline-secondary" type="button" @click="filtroError = ''">x</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-12 ">
                                                    <label for="f-descripcion">Descripción</label>
                                                    <div class="input-group ">
                                                        <input type="text" class="search form-control" v-model="filtroDescripcion" id="f-descripcion" name="filterDescripcion">
                                                        <button class="btn btn-outline-secondary" type="button" @click="filtroDescripcion = ''" >x</button>
                                                    </div>
                                                </div>
                                            </div>	
                                        </form>	
                                    </div>	
                                </div>
                            </div>
                        </div>
                        
                        <section class="users-list-wrapper">
                            <div class="users-list-table">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col form-group">
                                                    <label>Mostrar Errores: </label>
                                                    <select id="sistema-filter" v-model="filtro" @change="getErrores()" class="form-control" name="Sistema">
                                                        <option value="wdtst">Errores WinDev</option>
                                                        <option v-for="sistema in sistemas" :key="sistema.SistemasID" :value="(sistema.Nombre.toLowerCase())">{{ sistema.Nombre}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="table" >
                                                <div class="row table-header border-bottom border-top d-none d-md-flex pb-3 pt-3">
                                                    <div class="col-md-2"><input type="checkbox" v-model="allSelected"  @change="selectAll()" >
                                                        <button class="ms-3 me-3 btn btn-sm btn-danger" @click="deleteSelectedError()"><TrashCan /></button>Sistema</div>
                                                    <div class="col-md-2">Datos Personales</div>
                                                    <div class="col-md-2">Query</div>
                                                    <div class="col-md-2">Error</div>
                                                    <div class="col-md-3">Descripción</div>
                                                    <div class="col-md-1"></div>
                                                </div>
                                                
                                                <LoadingSpinner v-if="loading" />

                                                <div class="row border-bottom pt-2" 
                                                        v-for="(error,v) in filteredErrores" :key="error.NumID"
                                                    @click="selectedRegistro = error.NumID" 
                                                    :class="{'selected-row': (selectedRegistro == error.NumID)}">
                                                    <div class="col-md-2 cursor-pointer col-12"> 
                                                        <input @change="error.selected = $event.target.checked" type="checkbox" class="d-none d-md-block error-check" v-model="selectedErrors" :value="error.NumID">
                                                        <div @click="error.opened = !error.opened" class="error-left">
                                                            <b>{{ error.NumID }}</b><br>
                                                            <b> {{ error.Programa }}</b><br>
                                                            {{ error.Fecha}}<br>
                                                            {{error.Sistema}}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 cursor-pointer col-12" @click="error.opened = !error.opened"><b class="d-md-none">Datos Personales: <br></b>{{ error.opened }} {{ error.Personales }}</div>
                                                    <div class="col-md-2 cursor-pointer col-12" @click="error.opened = !error.opened"><b class="d-md-none">Query: <br></b> {{ error.QuerySM }}</div>
                                                    <div class="col-md-2 cursor-pointer col-12" @click="error.opened = !error.opened"><b class="d-md-none">Error: <br></b> {{ error.ErrorSM }}</div>
                                                    <div class="col-md-3 cursor-pointer col-12" @click="error.opened = !error.opened"><b class="d-md-none">Descripción: <br></b>{{ error.DescripcionSM }}</div>
                                                    <div class="col-md-1 cursor-pointer col-12"><button @click="deleteSingleError(error.NumID,v)"  class=" btn btn-sm btn-danger dd mb-2"><TrashCan /></button></div>
                                                    
                                                    <div class="col-md-12 p-2" v-if="error.opened">
                                                        <div class="row hiddenRow" >
                                                            <div class="col-md-12" >
                                                                <div class="row pb-2 border-bottom">
                                                                    <div class="col-md-1">Query</div>
                                                                    <div class="col-md-10">{{ error.Query }}</div>
                                                                    <div class="col-md-1"><button v-clipboard:copy="error.Query" v-clipboard:success="onCopy" class="copy-clipboard btn btn-sm dd btn-warning">Copiar</button></div>
                                                                </div>
                                                                <div class="row pb-2  border-bottom">
                                                                    <div class="col-md-1">Error</div>
                                                                    <div class="col-md-10">{{ error.Error }}</div>
                                                                    <div class="col-md-1"><button v-clipboard:copy="error.Error" v-clipboard:success="onCopy" class="copy-clipboard btn btn-sm dd btn-warning">Copiar</button></div>
                                                                </div>
                                                                <div class="row pb-2 border-bottom">
                                                                    <div class="col-md-1">Descripción</div>
                                                                    <div class="col-md-10">{{ error.Descripcion }}</div>
                                                                    <div class="col-md-1"><button v-clipboard:copy="error.Descripcion" v-clipboard:success="onCopy" class="copy-clipboard btn btn-sm dd btn-warning">Copiar</button></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- datatable ends -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <notifications />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import moment from 'moment'
import TrashCan from 'vue-material-design-icons/TrashCan.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name: "ErroresSql",
    components:{LoadingSpinner,SideBar,TopBar,TrashCan},
    data() {
        return {
            versiones: {},
            errores: {},
            sistemas: {},
            filtro: "wdtst",
            filtroSistema: "",
            filtroQuery: "",
            filtroDatos: "",
            filtroDescripcion: "",
            filtroError: "",
            filtroTipo: "todos",
            filtroFecha: "todo",
            filtroFechaIni: "",
            filtroFechaFin: "",
            selectedErrors: [],
            showBox: false,
            loading: false,
            show:true,
            allSelected: false,
            selectedRegistro:0
        };
    },
    created() {
        this.getVersiones();
        this.getSistemas();
        this.getErrores();
    },
    methods: {
        getVersiones() {
            fetch(this.$url+'/versiones', {
                method: "GET",
                headers: {  "Content-type" : "application/json;charset=UTF-8" , 
                            'Authorization': this.$store.state.prtiasctkn },
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
            }).then((data) => {
                this.versiones = data;
            });
        },
        getSistemas() {
            fetch(this.$url+'/sistemas', {
                method: "GET",
                headers: {  "Content-type" : "application/json;charset=UTF-8" , 
                            'Authorization': this.$store.state.prtiasctkn },
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
            }).then((data) => {
                this.sistemas = data;
            });
        },
        getErrores() {
            this.loading = true;
            fetch(this.$url+'/fetch-errores/'+this.filtro, {
                method: "GET",
                headers: {  "Content-type" : "application/json" , 
                            'Authorization': this.$store.state.prtiasctkn },
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
            }).then((data) => {
                this.errores = data;
            }).finally(() => {
                this.loading = false;
            });
        },
        deleteSingleError(errorID, indx) {
            fetch(this.$url+'/deleteError/'+errorID, {
                method: "POST",
                headers: {  "Content-type" : "application/json" , 
                            'Authorization': this.$store.state.prtiasctkn },
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
            }).then((data) => {
                if (data.message == 'done') {
                    this.errores.splice(indx, 1);
                    if(this.filteredErrores.length < 1 ){
                        this.resetFiltros()
                    }
                }
            })
        },
        deleteSelectedError() {
            fetch(this.$url+'/deleteSelectedErrors', {
                method: "POST",
                headers: {  "Content-type" : "application/json" , 
                            'Authorization': this.$store.state.prtiasctkn },
                body: JSON.stringify({ids: this.selectedErrors})
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
            }).then((data) => {
                if (data.message == 'done') {
                    this.errores = this.errores.filter((err) => {
                        return err.selected != true
                    });
                    this.selectedErrors = []
                    this.allSelected = false
                    if(this.filteredErrores.length < 1 ){
                        this.resetFiltros()
                    }
                }
            })
        },
        resetFiltros(){
            this.filtroSistema = ""
            this.filtroQuery = ""
            this.filtroDatos = ""
            this.filtroDescripcion = ""
            this.filtroError = ""
        },
        selectAll() {
            if (this.allSelected) {
                this.filteredErrores.forEach((e) => e.selected = true)
                const selected = this.filteredErrores.map((u) => u.NumID)
                this.selectedErrors = selected;
            } else {
                this.selectedErrors = [];
            }
        },
        onCopy(){
            this.$notify({
                title: "Aviso",
                text: "Se ha copiado el error a la papelera",
                duration: 3000
            });
        }
    },
    computed: {
        filteredErrores() {
            let filtered = this.errores;
                if(filtered.length > 0){
                filtered = filtered.filter((item) => {
                    return item.Programa.toLowerCase().includes(this.filtroSistema.toLowerCase()) ||
                        item.NumID.toString().toLowerCase().includes(this.filtroSistema.toLowerCase()) ||
                        item.Sistema.toLowerCase().includes(this.filtroSistema.toLowerCase()) ||
                        item.Fecha.toLowerCase().includes(this.filtroSistema.toLowerCase());
                }).filter((item) => {
                    return item.Personales.toLowerCase().includes(this.filtroDatos.toLowerCase());
                }).filter((item) => {
                    return item.Query.toLowerCase().includes(this.filtroQuery.toLowerCase());
                }).filter((item) => {
                    return item.Error.toLowerCase().includes(this.filtroError.toLowerCase());
                }).filter((item) => {
                    return item.Descripcion.toLowerCase().includes(this.filtroDescripcion.toLowerCase());
                }).filter((item) => {
                    if (this.filtroTipo == "todos") {
                        return item;
                    }
                    return item.Tipo.toLowerCase().includes(this.filtroTipo.toLowerCase());
                }).filter((item) => {
                    if (this.filtroFecha == "todo") {
                        return item;
                    }
                    if (this.filtroFecha == "hoy") {
                        let hoy = moment().format("YYYY-MM-DD");
                        return item.Fecha == hoy;
                    }
                    if (this.filtroFecha == "ayer") {
                        let yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
                        return item.Fecha == yesterday;
                    }
                    if (this.filtroFecha == "una") {
                        let weekago = moment().subtract(7, "days").format("YYYY-MM-DD");
                        return item.Fecha == weekago;
                    }
                }).filter((item) => {
                    if (this.filtroFechaIni != "") {
                        return item.Fecha >= this.filtroFechaIni;
                    }
                    return item;
                }).filter((item) => {
                    if (this.filtroFechaFin != "") {
                        return item.Fecha <= this.filtroFechaFin;
                    }
                    return item;
                });
                return filtered;
            }else{
                return filtered
            }
        }
    }
}
</script>