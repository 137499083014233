<template>
   <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-6 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Licencias</h3>
                        </div>
                    </div>
                    <div class="content-body">
                        <section class="users-list-wrapper">
                            <div class="users-list-table">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-8">
                                                    <div class="table">
                                                        <div class="row d-none d-md-flex table-header border-bottom border-top">
                                                            <div class="col-1 d-flex">
                                                                <div class="form-check">
                                                                    <input class="form-check-input select-cbox" type="checkbox" v-model="allSelected" @click="selectAll()" >
                                                                </div>

                                                                <div class="btn-group dropend mb-2">
                                                                    <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">...</button>
                                                                    <div class="dropdown-menu">
                                                                        <a class="dropdown-item" href="#" @click="archivar()" >Archivar</a>
                                                                        <a v-show="false" class="dropdown-item" href="#" @click="eliminar()" >Eliminar</a>
                                                                        <div class="dropdown-divider">Mostrar</div>
                                                                        <a class="dropdown-item" href="#" @click="getLicencias()">Ver solo licencias no archivadas</a>
                                                                        <a class="dropdown-item" href="#" @click="getDataArchivados()">Ver solo licencias archivadas</a>
                                                                        <a class="dropdown-item" href="#" @click="getDataTodos()">Ver todas</a>
                                                                    </div>
                                                                </div>                
                                                            </div>
                                                            <div class="col-1">No.</div>
                                                            <div class="col-2">Empresa</div>
                                                            <div class="col-1">RFC</div>
                                                            <div class="col-2">Sistema / Máquina</div>
                                                            <div class="col-1">Fecha Vence</div>
                                                            <div class="col-2">Estatus</div>
                                                            <div class="col-2">Ultima Com. <br> (dias)</div>
                                                        </div>
                                                        <LoadingSpinner v-if="loading" />
                                                        <div v-if="vencidas.length > 0 && licencias.length > 0">
                                                            <div class="col-12 text-center p-2">
                                                               <h4>RESULTADOS DE BUSQUEDA</h4>
                                                            </div>
                                                        </div>
                                                        <div @dblclick="goto('/licencias/modifica/'+lic.LicenciasID)"
                                                            @click="selectedRegistro = lic.LicenciasID" 
                                                            :class="{'selected-row': (selectedRegistro == lic.LicenciasID)}"
                                                            class="row row-table border-bottom pt-1 cursor-pointer" 
                                                            v-for="(lic,x) in licencias" 
                                                            :key="x">
                                                            <div class="col-1">
                                                                <div class="form-check">
                                                                    <input class="form-check-input select-cbox" type="checkbox" v-model="selectedIds" @click="select()" :value="lic.LicenciasID">
                                                                </div>
                                                            </div>
                                                            <div class="col-1 d-none d-md-block"><b>{{ lic.LicenciasID }}</b></div>
                                                            <div class="col-12 col-md-2"><b class="d-md-none">{{ lic.LicenciasID }} - </b><b>{{ lic.NombreCte}}</b></div>
                                                            <div class="col-12 col-md-1">{{ lic.rfc}}</div>
                                                            <div class="col-md-2 d-none d-md-block"><b>{{ lic.NombreSis }}</b><br>{{ lic.Computadora }}<br>{{ lic.MacAddress }}<br>{{ lic.Serial }}<br>Usuarios: {{ lic.Usuarios }} </div>
                                                            <div class="col-6 d-md-none"><b>Sistema: {{ lic.NombreSis }}</b><br>Máquina: {{ lic.Computadora }}<br>MAC Address: {{ lic.MacAddress }}<br>Serial: {{ lic.Serial }}<br>Usuarios: {{ lic.Usuarios }} </div>
                                                            <div class="col-md-1 col-6" :class="[ lic.Estatus != 'Vigente' ? 'text-danger ' : '']"><b class="d-md-none">Fecha Vence: </b>{{ formato(lic.Vencimiento,"DD-MMM-YYYY") }}</div>
                                                            <div class="col-md-2 col-12"><span class="badge badge-pill float-right" style="white-space: pre-line;" :class="[lic.Estatus == 'Vigente' ?  'bg-success' : 'bg-danger']">{{ lic.Estatus }}</span></div>
                                                            <div class="col-md-2 d-none d-md-block"> {{ formato(lic.UltimaComunicacion,"DD-MMM-YYYY")}}<br><b>({{ lic.dias }})</b></div>
                                                            <div class="d-md-none col-12 text-end"> {{ formato(lic.UltimaComunicacion,"DD-MMM-YYYY")}} <b>({{ lic.dias }})</b></div>
                                                        </div>
                                                        <div v-if="vencidas.length > 0 && licencias.length > 0">
                                                            <div class="col-12 text-center p-2">
                                                               <h4>VENCIDAS</h4>
                                                            </div>
                                                        </div>
                                                        <div @dblclick="goto('/licencias/modifica/'+lic.LicenciasID)"
                                                            @click="selectedRegistro = lic.LicenciasID" 
                                                            :class="{'selected-row': (selectedRegistro == lic.LicenciasID)}"
                                                            class="row row-table border-bottom pt-1 cursor-pointer" 
                                                            v-for="(lic,x) in vencidas" 
                                                            :key="x">
                                                            <div class="col-1">
                                                                <div class="form-check">
                                                                    <input class="form-check-input select-cbox" type="checkbox" v-model="selectedIds" @click="select()" :value="lic.LicenciasID">
                                                                </div>
                                                            </div>
                                                            <div class="col-1 d-none d-md-block"><b>{{ lic.LicenciasID }}</b></div>
                                                            <div class="col-12 col-md-2"><b class="d-md-none">{{ lic.LicenciasID }} - </b><b>{{ lic.NombreCte}}</b></div>
                                                            <div class="col-12 col-md-1">{{ lic.rfc}}</div>
                                                            <div class="col-md-2 d-none d-md-block"><b>{{ lic.NombreSis }}</b><br>{{ lic.Computadora }}<br>{{ lic.MacAddress }}<br>{{ lic.Serial }}<br>Usuarios: {{ lic.Usuarios }} </div>
                                                            <div class="col-6 d-md-none"><b>Sistema: {{ lic.NombreSis }}</b><br>Máquina: {{ lic.Computadora }}<br>MAC Address: {{ lic.MacAddress }}<br>Serial: {{ lic.Serial }}<br>Usuarios: {{ lic.Usuarios }} </div>
                                                            <div class="col-md-1 col-6" :class="[ lic.Estatus != 'Vigente' ? 'text-danger ' : '']"><b class="d-md-none">Fecha Vence: </b>{{ formato(lic.Vencimiento,"DD-MMM-YYYY") }}</div>
                                                            <div class="col-md-2 col-12"><span class="badge badge-pill float-right" style="white-space: pre-line;" :class="[lic.Estatus == 'Vigente' ?  'bg-success' : 'bg-danger']">{{ lic.Estatus }}</span></div>
                                                            <div class="col-md-2 d-none d-md-block"> {{ formato(lic.UltimaComunicacion,"DD-MMM-YYYY")}}<br><b>({{ lic.dias }})</b></div>
                                                            <div class="d-md-none col-12 text-end"> {{ formato(lic.UltimaComunicacion,"DD-MMM-YYYY")}} <b>({{ lic.dias }})</b></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div id="filtros" class="row">
                                                        <div class="col-lg-12 col-md-12 col-12">
                                                            <div class="card p-4">
                                                                <div class="card-header">
                                                                    <h4 class="card-title">Busqueda Avanzada</h4>
                                                                </div>
                                                                
                                                                <div class="card-content collapse show pt-3">
                                                                    <form @submit.prevent="Busqueda()">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                <label for="f-descripcion">Licencia</label>
                                                                                <div class="input-group ">
                                                                                    <input type="number" class="search form-control" v-model="filtroLicencia" id="f-lic" name="filtroLicencia">
                                                                                    <button class="btn btn-outline-secondary" type="button" @click="filtroLicencia = 0" >x</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <label for="f-descripcion">Empresa</label>
                                                                                <div class="input-group ">
                                                                                    <input type="text" class="search form-control" v-model="filtroEmpresa" id="f-empresa" name="filteroEmpresa">
                                                                                    <button class="btn btn-outline-secondary" type="button" @click="filtroEmpresa = ''" >x</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <label for="f-descripcion">RFC</label>
                                                                                <div class="input-group ">
                                                                                    <input type="text" class="search form-control" v-model="filtrorfc" id="f-rfc" name="filterorfc">
                                                                                    <button class="btn btn-outline-secondary" type="button" @click="filtrorfc = ''" >x</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <label for="f-datospersonales">Sistema</label>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="search form-control" v-model="filtroSistema"  id="f-sistema" name="filterSistema">
                                                                                    <button class="btn btn-outline-secondary" @click="filtroSistema = ''" type="button">x</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6 ">
                                                                                <label for="f-query">Máquina</label>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="search form-control "  v-model="filtroMaquina" id="f-maquina" name="filterMaquina">
                                                                                    <button class="btn btn-outline-secondary" type="button" @click="filtroMaquina = ''" >x</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12">
                                                                                <label for="f-error">MAC Address</label>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="search form-control " v-model="filtroMac" id="f-mac" name="filterMac">
                                                                                    <button class="btn btn-outline-secondary" type="button" @click="filtroMac = ''">x</button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 d-flex justify-content-end">
                                                                                <button type="submit" class="btn btn-sm btn-success">Buscar Licencia</button>
                                                                            </div>
                                                                        </div>	
                                                                    </form>	
                                                                </div>	
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </section>    
</template>

<script>
import moment from 'moment'
import axios from 'axios'
require('moment/locale/es') 
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name: 'LicenciasBuscarComponent',
    components:{LoadingSpinner,SideBar,TopBar},
    data(){
        return{
            licencias: [],
            vencidas: [],
            loading: false,
            selectedRegistro:0,
            selectedIds:[],
            selected: [],
            allSelected: false,
            filtroEmpresa:'',
            filtroLicencia:0,
            filtrorfc:'',
            filtroSistema:'',
            filtroMaquina:'',
            filtroMac:''
        }
    },
    created(){
        this.getLicencias()
    },
    methods:{
        goto(link){
            this.$router.push(link)
        },
        selectAll() {
            this.selectedIds = [];
            if(!this.allSelected){
                for(var i=0;i < this.licencias.length;i++) {
                    this.selectedIds.push(this.licencias[i].id.toString());
                }
            }
        },
        select() {
            this.allSelected = false;
        },
        async Busqueda(){
            const data = { sistema: this.filtroSistema, 
                                mac: this.filtroMac,    
                                licencia: this.filtroLicencia,
                                empresa: this.filtroEmpresa, 
                                rfc: this.filtrorfc, 
                                maquina: this.filtroMaquina }
            await axios.post(this.$url+'/buscar-licencia',data,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.prtiasctkn
                }
            })
            .then((res)=>{   
                if(res.data.message){
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: res.data.message,
                            type: 'error'
                        })
                    }else{
                        this.licencias = res.data;
                    }
              
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        archivar(){
            const data = {
                licencias_ids : this.selectedIds
            }
            axios.post(this.$url+'/licencias-archivar',data,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.prtiasctkn
                }
            })
            .then((res)=>{
                if(res.data.message == 'done'){
                    this.allSelected = false
                    this.selectedIds = []
                    this.getLicencias()
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataTodos(){
            axios.get(this.$url+'/licencias-w-archive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.prtiasctkn
                }
            })
            .then((res)=>{
                this.licencias = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataArchivados(){
            axios.get(this.$url+'/licencias-archive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.prtiasctkn
                }
            })
            .then((res)=>{
                this.licencias = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        eliminar(){
            const data = {
                licencias_ids: this.selectedIds
            }
            axios.post(this.$url+'/licencias-delete-selected',data,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message == 'done'){
                    this.allSelected = false
                    this.getLicencias()
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        async getLicencias(){
            await axios.get(this.$url+'/licencias/Vencida',{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': this.$store.state.prtiasctkn
              }
            }).then((res)=>{
                    if(res.data.message){
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: res.data.message,
                            type: 'error'
                        })
                    }else{
                        this.vencidas = res.data;
                    }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                })
            }).finally(()=>{
                this.loading = false
            })
        },
        
        formato(date,format){
            return moment(date).format(format)
        }
    },
    
}
</script>