<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-6 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Usuarios</h3>
                        </div>
                    </div>
                    <div class="content-body">
                        <section class="users-edit">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
                                       
                                        <div class="tab-content">
                                            <div class="tab-pane active" id="account" aria-labelledby="account-tab" role="tabpanel">
                                                <form @submit.prevent="setUsuario()">
                                                    <div class="row">
                                                        <div class="col-md-6 col-12">
                                                            <div class="form-group">
                                                                <div class="controls">
                                                                    <label>Nombre</label>
                                                                    <input type="text" class="form-control" v-model="usuario.name" required >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-12 ">
                                                            <div class="form-group">
                                                                <label>Alias</label>
                                                                <input type="text" class="form-control" v-model="usuario.alias">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 ">
                                                            <div class="form-group">
                                                                <label>Activo</label>
                                                                <select class="form-control" v-model="usuario.activo" >
                                                                    <option value="1" >Si</option>
                                                                    <option value="0" >No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 ">
                                                            <div class="form-group">
                                                                <label>Bloqueado</label>
                                                                <select class="form-control" v-model="usuario.bloqueado" >
                                                                    <option value="1" >Si</option>
                                                                    <option value="0" >No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input type="email" class="form-control" v-model="usuario.email">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <label>Teléfono</label>
                                                                <input type="text" class="form-control" v-model="usuario.telefono">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <label>Password</label>
                                                                <input type="text" class="form-control" v-model="usuario.password_2">
                                                            </div>
                                                        </div>
                                                    
                                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                                            <button type="submit" class="btn btn-sm me-2 btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">
                                                            {{ mode != 'edit' ? 'Agregar Usuario' : 'Guardar Cambios' }}
                                                            </button>
                                                            <router-link to="/usuarios" class="btn btn-sm btn-outline-danger">Cancelar</router-link>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
     </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner.vue';
export default {
    name: 'UsuariosFormComponent',
    components:{SideBar,TopBar,LoadingSpinner},
    data(){
        return{
            usuario: {},
            empresas: [],
            roles: [],
            mode: this.$route.params.id ? 'edit' : 'add',
            loading:false
        }
    },
    created(){
     
        if(this.mode == 'edit'){
            this.fetchUsuario()
        }
    },
    methods:{
       
    
        fetchUsuario(){
            this.loading = true

            axios.get(this.$url+'/fetch-usuario/'+this.$route.params.id,{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': this.$store.state.prtiasctkn
            }
            }).then((res)=>{

                if(res.data.message){
                    this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: res.data.message,
                    type: 'error'
                })
                
                }else{
                    this.usuario = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                })
            }).finally(()=>{
                this.loading = false
            })
        },
        setUsuario(){
            this.loading = true

            axios.post(this.$url+'/'+(this.mode == 'edit' ? 'set-usuario/'+this.usuario.id : 'set-usuario'),this.usuario,{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': this.$store.state.prtiasctkn
            }
            }).then((res)=>{
                if(res.data.message){
                    this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: res.data.message,
                    type: 'error'
                })
                }else{
                    this.$router.push('/usuarios')
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                })
            }).finally(()=>{
                this.loading = false
            })
        }
    }
}
</script>