<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-6 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Mi perfil</h3>
                        </div>
                    </div>
                    <div class="content-body">
                        <section class="users-edit">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
                                        <ul class="nav nav-tabs mb-2" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link d-flex align-items-center active" id="account-tab" data-toggle="tab" href="#account" aria-controls="account" role="tab" aria-selected="true">
                                                    <i class="feather icon-user mr-25"></i><span class="d-none d-sm-block">Mis datos</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane active" id="account" aria-labelledby="account-tab" role="tabpanel">
                                                <form @submit.prevent="setUsuario()">
                                                    <div class="row">
                                                        <div class="col-md-6 col-12">
                                                            <div class="form-group">
                                                                <div class="controls">
                                                                    <label>Nombre</label>
                                                                    <input type="text" class="form-control" v-model="userData.name" required >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-12 ">
                                                            <div class="form-group">
                                                                <label>Alias</label>
                                                                <input type="text" class="form-control" v-model="userData.alias">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input type="email" class="form-control" v-model="userData.email">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <label>Teléfono</label>
                                                                <input type="text" class="form-control" v-model="userData.telefono">
                                                            </div>
                                                        </div>
                                                    
                                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                                            <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">Guardar Cambios</button>
                                                            <button type="button" @click="goBack()" class="btn btn-outline-danger">Cancelar</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
     </section>    
</template>

<script>
import axios from 'axios'
import LoadingSpinner from './LoadingSpinner.vue';
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name: 'PerfilFormComponent',
    components:{SideBar,TopBar,LoadingSpinner},
    data(){
        return{
            userData: {},
            loading:false
        }
    },
    created(){
        this.userData = JSON.parse(localStorage.getItem('user'))
    },
    methods:{
        goBack(){
            this.$router.back()
        },
        setUsuario(){
            this.loading = true

            axios.post(this.$url+'/edt-usuario/'+this.userData.id,this.usuario,{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': this.$store.state.prtiasctkn
              }
            }).then((res)=>{
                    if(res.data.message){
                        this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    })
                    }else{
                        this.$router.back()
                    }
                }).catch((err)=>{
                    if(err.response) {
                        if(err.response.status == 500) this.logout()
                    }
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    })
                }).finally(()=>{
                    this.loading = false
                })
        }
    }
}
</script>