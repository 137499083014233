<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-8 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Pólizas (Control de Licencias)</h3>
                        </div>
                        <div class="content-header-right text-md-right col-md-4 col-12 text-end">
                            <div class="form-group">
                                <router-link to="/polizas/nuevo-cliente" class=" btn btn-sm btn-secondary" type="button">
                                    Nuevo Cliente
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <section class="users-list-wrapper">
                            <div class="users-list-table">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <form>
                                                <div class="input-group mb-2">
                                                    <input type="text" class="form-control" v-model="filtro" placeholder="Escribe tu búsqueda..">
                                                    <button class="btn btn-outline-secondary" type="reset" @click="filtro = ''"  id="button-addon2">x</button>
                                                </div>
                                            </form>
                                            <!-- datatable start -->
                                            <div class="table">
                                                <div class="row d-none d-md-flex table-header border-bottom border-top">
                                                    <div class="col-1 d-flex">
                                                        <div class="form-check">
                                                            <input class="form-check-input select-cbox" type="checkbox" v-model="allSelected" @click="selectAll()" >
                                                        </div>

                                                        <div class="btn-group dropend mb-2">
                                                            <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">...</button>
                                                            <div class="dropdown-menu">
                                                                <a class="dropdown-item" href="#" @click="archivar()" >Archivar</a>
                                                                <a v-show="false" class="dropdown-item" href="#" @click="eliminar()" >Eliminar</a>
                                                                <div class="dropdown-divider">Mostrar</div>
                                                                <a class="dropdown-item" href="#" @click="getClientes()">Ver solo pólizas no archivadas</a>
                                                                <a class="dropdown-item" href="#" @click="getDataArchivados()">Ver solo pólizas archivadas</a>
                                                                <a class="dropdown-item" href="#" @click="getDataTodos()">Ver todas</a>
                                                            </div>
                                                        </div>                
                                                    </div>
                                                    <div class="col-2">Cliente</div>
                                                    <div class="col-1 p-0">Factura</div>
                                                    <div class="col-1 p-0">Periodo</div>
                                                    <div class="col-1 text-nowrap" >Fecha Exp.<br>Actualizaciones</div>
                                                    <div class="col-2">Sistemas Contratados</div>
                                                    <div class="col-1">PAC</div>
                                                    <div class="col-1">Empresas Relacionadas</div>
                                                    <div class="col-1 text-center">Estatus</div>
                                                    <div class="col-1 text-end">Folios</div>
                                                </div>
                                                <LoadingSpinner v-if="loading" />
                                                <div 
                                                        @dblclick="goto('/polizas/modifica/'+cte.ClientesID)" 
                                                        @click="selectedRegistro = cte.ClientesID" 
                                                        :class="{'selected-row': (selectedRegistro == cte.ClientesID)}"
                                                        class="row row-table border-bottom pt-1 cursor-pointer" 
                                                        v-for="cte in filteredClientes" 
                                                        :key="cte.ClientesID">
                                                  
                                                    <div class="col-1">
                                                        <div class="form-check">
                                                            <input class="form-check-input select-cbox" type="checkbox" v-model="selectedIds" @click="select()" :value="cte.ClientesID">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-2">
                                                        <div class="row">
                                                            <div class="col-4"><b>{{ cte.ClientesID | leadingZeros(5) }}</b> </div>
                                                            <div class="col-8  ps-0"><b class="d-md-none">{{ cte.ClientesID | leadingZeros(5) }} - </b>
                                                                <b>{{ cte.Nombre }}</b><br>RFC: {{ cte.RFC }}</div>
                                                            <div class="col-4"></div>
                                                            <div class="col-8">
                                                                <span v-if="cte.Comentarios" style="font-style:italic">Comentarios: {{cte.Comentarios}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-1 text-nowrap p-0">
                                                        Folio: <b>{{ cte.poliza.POL_FACTURA | leadingZeros(5) }}</b><br>
                                                        Fecha: {{ formato(cte.poliza.POL_FECHA_FAC,"DD-MMM-YYYY") }}<br>Pago: {{ formato(cte.poliza.POL_FECHA_PAGO,"DD-MMM-YYYY") }}
                                                    </div>
                                                    <div class="col-6 col-md-1 text-nowrap">Ini: {{ formato(cte.poliza.POL_FECHA_INI,"DD-MMM-YYYY")  }}<br>Fin: {{ formato(cte.poliza.POL_FECHA_FIN,"DD-MMM-YYYY") }}</div>
                                                    <div class="col-12 col-md-1 text-nowrap"><b class="d-md-none">Fecha Exp.<br>Actualizaciones: </b>{{ formato(cte.ExpActualizaciones, 'DD-MMM-YYYY') }}</div>
                                                    <div class="col-12 col-md-2">{{getSistemas(cte.poliza)}}</div>
                                                    <div class="col-12 col-md-1">PAC: {{cte.PAC}}<br>NOM: {{cte.NOMINA}}</div>
                                                    <div class="col-12 col-md-1">{{cte.Empresas_relacionadas}} </div>
                                                    <div class="col-12 col-md-1 text-end"><span class="badge badge-pill float-right" :class="'bg-'+(cte.Estatus == 'Vigente' ?  'success' : 'danger')">{{ cte.Estatus }}</span></div>
                                                    <div class="col-12 col-md-1 text-end"><b class="d-md-none ">Folios: </b>{{ cte.FoliosCalc }}</div>
                                                </div>
                                            </div>
                                            <!-- datatable ends -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
       </div>
    </section>
</template>

<script>
import axios from 'axios'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import moment from 'moment'
require('moment/locale/es')
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue'

export default {
    name: "LicenciasComponent",
    components: { LoadingSpinner ,SideBar,TopBar,PencilIcon},
    data() {
        return {
            clientes: [],
            filtro: "",
            loading:false,
            selectedRegistro:0,
            selectedIds:[],
            selected: [],
            allSelected: false,
        };
    },
    created() {
        this.getClientes();
    },
    computed: {
        filteredClientes() {
            let filtered = this.clientes;
            if (this.filtro == "") return filtered

            filtered = filtered.filter(item => Object.keys(item).map((key) => item[key].toString().toLocaleLowerCase().includes(this.filtro.toLocaleLowerCase().trim())).includes(true));
           
            return filtered;
        },

    },
    methods: {
       
        goto(link){
            this.$router.push(link)
        },
        formato(fecha,formato){
            return moment(fecha).format(formato)
        },
        getSistemas(poliza) {
            let sistemas = "";
            sistemas += (poliza.POL_FUSSION == "S" ? (sistemas != "" ? ", " : "") + "Fussion" : "");
            sistemas += (poliza.POL_FACTURACION == "S" ? (sistemas != "" ? ", " : "") + "Facturación" : "");
            sistemas += (poliza.POL_MANTENIMIENTO == "S" ? (sistemas != "" ? ", " : "") + "Mantenimiento" : "");
            sistemas += (poliza.POL_NOMINAS == "S" ? (sistemas != "" ? ", " : "") + "Nóminas" : "");
            sistemas += (poliza.POL_CONTABILIDAD == "S" ? (sistemas != "" ? ", " : "") + "Contabilidad" : "");
            sistemas += (poliza.POL_INVENTARIOSCOMPRAS == "S" ? (sistemas != "" ? ", " : "") + "Inventarios y Compras" : "");
            sistemas += (poliza.POL_CONSTRUCCION == "S" ? (sistemas != "" ? ", " : "") + "Construcción" : "");
            return sistemas;
        },
        getEmpresas(poliza) {
            let sistemas = "";
            sistemas += (poliza.POL_FUSSION == "S" ? (sistemas != "" ? ", " : "") + "Fussion" : "");
            sistemas += (poliza.POL_FACTURACION == "S" ? (sistemas != "" ? ", " : "") + "Facturación" : "");
            sistemas += (poliza.POL_MANTENIMIENTO == "S" ? (sistemas != "" ? ", " : "") + "Mantenimiento" : "");
            sistemas += (poliza.POL_NOMINAS == "S" ? (sistemas != "" ? ", " : "") + "Nóminas" : "");
            sistemas += (poliza.POL_CONTABILIDAD == "S" ? (sistemas != "" ? ", " : "") + "Contabilidad" : "");
            sistemas += (poliza.POL_INVENTARIOSCOMPRAS == "S" ? (sistemas != "" ? ", " : "") + "Inventarios y Compras" : "");
            sistemas += (poliza.POL_CONSTRUCCION == "S" ? (sistemas != "" ? ", " : "") + "Construcción" : "");
            return sistemas;
        },
        async getClientes() {
            this.loading = true

            await axios.get(this.$url+'/ctes-licencias',{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': this.$store.state.prtiasctkn
              }
            }).then((res)=>{
                    if(res.data.message){
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: res.data.message,
                            type: 'error'
                        })
                    }else{
                        this.clientes = res.data;
                    }
                }).catch((err)=>{
                    if(err.response) {
                        if(err.response.status == 500) this.logout()
                    }
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    })
                }).finally(()=>{
                    this.loading = false
                })
        },
        
        selectAll() {
            this.selectedIds = [];
            if(!this.allSelected){
                for(var i=0;i < this.clientes.length;i++) {
                    this.selectedIds.push(this.clientes[i].id.toString());
                }
            }
        },
        select() {
            this.allSelected = false;
        },
        archivar(){
            const data = {
                clientes_ids : this.selectedIds
            }
            axios.post(this.$url+'/clientes-archivar',data,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.prtiasctkn
                }
            })
            .then((res)=>{
                if(res.data.message == 'done'){
                    this.allSelected = false
                    this.selectedIds = []
                    this.getClientes()
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataTodos(){
            axios.get(this.$url+'/clientes-w-archive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.prtiasctkn
                }
            })
            .then((res)=>{
                this.clientes = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataArchivados(){
            axios.get(this.$url+'/clientes-archive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.prtiasctkn
                }
            })
            .then((res)=>{
                this.clientes = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
    },
    
}
</script>